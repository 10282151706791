<template>
  <div class="mainwidth">
    <h2>关于平台</h2>
    <div class="box-wrapper">
      <p>一、平台基本情况</p>
      <div>
        龙哈智能产融综合服务平台以金融科技赋能区域产业高质量发展为目标，采用领先的AI（人工智能）、大数据、区块链等新一代信息技术，基于客观数据要素资产和AI数据模型驱动，结合区域产业发展和业务运营实际情况，自2018年建设以来，在平台技术、功能应用上持续迭代更新至目前3.0版本。
      </div>
      <p>二、平台性质</p>
      <div>
        哈尔滨是国家产融合作平台第一批试点城市，在哈尔滨市工业和信息化局指导下，龙哈智能产融云平台与国家产融合作平台实现协同运营数据共享交换，是国家产融合作平台的哈尔滨节点，是工信部首批国家大中小融通专项资金项目，是哈尔滨市委全面深化改革办公室重点项目，国家信易贷哈尔滨示范站。
      </div>
      <p>三、平台荣誉</p>
      <div>
        荣获工信部首批10个地方产融合作平台入选十大典型案例，并入选《产融合作平台典型案例集》；工信部首批国家产融合作试点城市典型案例，并入选《国家产融合作试点城市典型案例集（2023）》；中国信通院“金信通”金融科技创新应用卓越案例奖，并被《2022“金信通”金融科技创新应用案例集
        》收录；“全国城市数字治理典型案例”；安永中国以该平台为示范案例在2022中国国际服贸会重磅发布“智能产融综合服务平台”。
      </div>
      <p>四、平台特色功能</p>
      <span>（一）多角色用户登录使用</span>
      <div>
        平台针对不同用户的需求特点，划分政府类、金融机构类与企业类三大类用户登录通道，以满足不同用户的个性化业务需求。
      </div>
      <span>（二）一码实现融资准入分析</span>
      <div>
        “龙哈信融通”将个体工商户和中小微企业及金融机构发布的金融产品进行聚合，形成统一的二维码入口，贷款人通过扫描二维码，即可任意选择金融产品，提交融资申请，获得可授信额度，综合评分、评级额度诊断分析报告。
      </div>
      <span>
        （三）一揽子金融综合服务
      </span>
      <div>
        平台搭载了多样化不同类别的金融服务产品供企业类用户进行选择，如银行贷款、股权投资、融资租赁、商业保理、转贷基金等。
      </div>
      <span>
        （四）一键式供需智能对接
      </span>
      <div>
        平台设立企业与机构两类资源池，企业和机构可以在资源池中实现双向选择自由，其通过系统智能匹配对接与自主选择两种方式，实现金融服务供需的智能对接与匹配。
      </div>
      <span>
        （五）全方位瞬时企业数据分析
      </span>

      <div>
        利用多种行业的数据风控模型，根据企业类用户工商数据、信用数据、财税数据、舆情数据等多维度数据，在线数据分析并瞬时生产企业深度分析报告，同时根据企业类用户自身数据形成企业数据驾驶舱。
      </div>
      <span>（六）全流程辅助风险管理</span>
      <div>
        贷前实现获客准入对接，贷中实现多维度多应用场景与数据分析，贷后实现按监管频率对企业类用户进行预警监控，辅助金融机构风险流程闭环管理。
      </div>
      <span>（七）多维度数据统计可视化展示</span>
      <div>
        平台对金融机构的服务效率、综合利率、服务用户数量、服务规模等维度数据进行统计并形成可视化看板，辅助政府对区域金融环境进行监测，提高金融服务机构参与度。
      </div>
      <span>（八）区域惠企政策支持与匹配</span>
      <div>
        平台让企业类用户第一时间掌握政策概要，打通政企之间消息壁垒的“最后一公里”。为用户提供政策申请专用通道，帮助政府做到精准施策并做好监管。
      </div>
      <span>（九）覆盖企业全生命周期管理的赋能服务</span>
      <div>
        平台为用户提供融资路演、上市辅导、财税咨询、法律咨询等多种赋能服务，满足企业类用户全生命周期发展。
      </div>

      <p>五、平台创新亮点</p>
      <div>
        平台围绕“信息对等、科技赋能、政府增信、系统风控”，创新实现政府、企业、银行、担保等信贷业务多终端、一站式、智能化的申请、审核、授信、审批、监管、预警。
      </div>
      <p>六、平台运行成果</p>
      <div>
        平台与浦发银行、龙江银行、中国农业银行、哈尔滨银行、储蓄银行等在内的21家金融机构深度合作，已发布金融产品45个，为400余家企业提供数据智能诊断服务，智能化评审额度达60余亿元，贷后监管资金20余亿元。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style lang="less" scoped>
.mainwidth {
  padding-top: 20px;
  padding-bottom: 20px;
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  .box-wrapper {
    p {
      font-weight: bold;
      font-size: 18px;
      margin-top: 20px;
    }
    span {
      font-weight: bold;
      margin-top: 20px;
      display: inline-block;
    }
    div {
      margin-top: 20px;
      text-indent: 32px;
    }
  }
}
</style>
